import React, { useContext, useState } from "react";
import { MapContext } from "../context/map-context";
import CollectedAFDefinition from "./collectedaf-definition";
import screenShot1 from "../assets/userscreenshot.png";
import screenShot2 from "../assets/profile.png";
import kblue from "../assets/CollectedAF_Icon.png"
import jjones from "../assets/CollectedAF_Icon.png"
import jm from "../assets/CollectedAF_Icon.png"
import { TitleContext } from "../context/title-context";
import collectedAFExplainer from "../assets/collectedAF_explainer.mp4";



const CollectedExperience = () => {
  const { titlePressed, setTitlePressed } = useContext(TitleContext) 
  const teamMembers = [
    { image: kblue, name: "Kenyon Blue", job: "CEO" },
    { image: jjones, name: "Jason Jones", job: "CMO" },
    { image: kblue, name: "Hassan Raza", job: "Senior Developer" },
    { image: kblue, name: "Perkins Coie", job: "Legal Counsel" },
    { image: kblue, name: "Rob Poleki", job: "Chief Advisor" },
    { image: jm, name: "Jacob Marcum", job: "Intern" },
    { image: jm, name: "Tyler Hilbig", job: "Intern - UVU" },
  ];
  
  const { showMap, setShowMap } = useContext(MapContext);
  const [showWhatWeAreDoing, setShowWhatWeAreDoing] = useState(true);
  const [applicationDescription, setApplicationDescription] = useState(false);
  const [ showOurTeam , setShowOurTeam ] = useState(false)

  const toggleHeckWedoin = () => {
    setApplicationDescription(false);
    setShowOurTeam(false)
    setShowWhatWeAreDoing(!showWhatWeAreDoing);
  };

  const togglePartnerLogin = () => {
    setShowWhatWeAreDoing(false);
    setShowOurTeam(false)
    setApplicationDescription(!applicationDescription);
  };

  const toggleMap = () => {
    setShowWhatWeAreDoing(false);
    setShowOurTeam(false)
    setApplicationDescription(false);
    setShowMap(!showMap);
  };

  const showAppImages = true;

  function handleShowOurTeam () {
    setShowOurTeam(!showOurTeam)
    setShowWhatWeAreDoing(false)
    setApplicationDescription(false)
  }

  const jacob = 25;

  return (
    <>
      <div className="w-full h-full flex flex-col">
        <div className="w-full flex-row">
          {/* if user is a partner we display this login */}
          {true && (
            <button
              onClick={() => handleShowOurTeam()}
              className={
                showOurTeam
                  ? "text-center mt-8 text-ORANGE  text-BLUE_AF mx-2 md:mx-8 w-68  px-4 py-2"
                  : "text-center mt-8 morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
              }
            >
              Our Team
            </button>
          )}
          {showAppImages && (
            <button
              onClick={() => togglePartnerLogin()}
              className={
                applicationDescription
                  ? "text-center mt-8 text-ORANGE  text-BLUE_AF mx-2 md:mx-8 w-68  px-4 py-2"
                  : "text-center mt-8 morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
              }
            >
              collectedAF App
            </button>
          )}
          {false && (
            <button
              onClick={() => toggleMap()}
              className={
                showMap
                  ? "text-center mt-8 text-ORANGE  text-BLUE_AF mx-2 md:mx-8 w-68  px-4 py-2"
                  : "text-center mt-8 morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
              }
            >
              Map
            </button>
          )}
          
          <button
            onClick={() => toggleHeckWedoin()}
            className={
              showWhatWeAreDoing
                ? "text-center text-sm md:text-base mt-8 text-BLUE_AF button-radius  border-GREY mx-2 md:mx-8 w-68  px-4 py-2"
                : "text-center mt-8 button-radius morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
            }
          >
            What in the heck Are We Doing?
          </button>
          <button
              onClick={() => setTitlePressed(!titlePressed)}
            className={
              false
                ? "text-center text-sm md:text-base mt-8 text-BLUE_AF button-radius  border-GREY mx-2 md:mx-8 w-68  px-4 py-2"
                : "text-center mt-8 button-radius morphic-attribute-button text-GREY mx-2 md:mx-8 w-68  px-4 py-2"
            }
          >
            
            Privacy Policy / Email / Investors
          </button>
        </div>
        {showOurTeam && (
      <div className="flex flex-wrap justify-around w-full h-full p-5 max-w-screen-lg mx-auto">
      {teamMembers.map((member, index) => (
        <div key={index} className="w-full md:w-1/4 flex max-w-xs p-2">
          <TeamMember image={member.image} name={member.name} job={member.job} />
        </div>
      ))}
    </div>
    )}
        {showWhatWeAreDoing && <CollectedAFDefinition />}
        {applicationDescription && (
  <div className="w-full h-full grid grid-cols-1 md:grid-cols-2 gap-8 items-center justify-center pt-4">
      <div className="flex items-center justify-start">
      <video
        className="w-full md:w-96 shadow-lg shadow-GREEN_AF" // Adjust styling as needed
        src={collectedAFExplainer}
        controls
        // alt="CollectedAF explainer video"
      />
    </div>
    <div className="hidden md:flex gap-8 items-center justify-start">
      <img
        className="w-36 shadow-lg shadow-BLUE_AF"
        src={screenShot1}
        alt=""
      />
        <img
        className="w-28 md:w-36 shadow-lg shadow-ORANGE"
        src={screenShot2}
        alt=""
      />
    </div>
    {/* <div className="flex items-center justify-start">
    
    </div> */}
  
  </div>
)}

      </div>
    </>
  );
};

const TeamMember = ({ name, job , image }) => (
  <div className="flex flex-col items-center text-center w-36">
    <img src={image} alt={name} className="w-24 h-24 rounded-10 object-cover mb-2 border-1-BLACK p-2" />
    <div className="font-bold mt-2">{name}</div>
    <div className="text-sm text-gray-500">{job}</div>
  </div>
);

export default CollectedExperience;
