import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div>
      <p style={{ fontSize: 8 }}> Privacy Policy </p>
      <p style={{ fontSize: 6 }}>
        collectedAF LLC built the collectedAF app as a Free app. This SERVICE is
        provided by collectedAF LLC at no cost and is intended for use as is.
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service. If you choose to use our Service, then you
        agree to the collection and use of information in relation to this
        policy. The Personal Information that we collect is used for providing
        and improving the Service. We will not use or share your information
        with anyone except as described in this Privacy Policy. The terms used
        in this Privacy Policy have the same meanings as in our Terms and
        Conditions, which are accessible at collectedAF unless otherwise defined
        in this Privacy Policy.
      </p>

      <ul style={{ fontSize: 8 }}>
        <li style={{ fontSize: 8 }}>Information regarding your location</li>
      </ul>
      <p style={{ fontSize: 6 }}>
        We use this information to provide features of Our Service, to improve
        and customize Our Service. The information may be uploaded to the
        collectedAF's servers and/or a Service Provider's server or it may be
        simply stored on Your device.
      </p>
      <p style={{ fontSize: 6 }}>
        You can enable or disable access to this information at any time,
        through Your Device settings.
      </p>
      <p style={{ fontSize: 6 }}>
        Additionally, collectedAF may collect location data while the app is in
        the background to enable continuous proximity-based features, such as
        displaying nearby users or updating collections based on location, even
        when the app is closed or not in use. Users can control and manage
        location permissions at any time through their device settings.
      </p>
      <p style={{ fontSize: 6 }}>
        With your consent, we may share your approximate location with other
        users within the app to enable location-based interactions.
      </p>

      <p style={{ fontSize: 8 }}> Information Collection and Use </p>
      <p style={{ fontSize: 6 }}>
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information, including
        but not limited to age, email, phone number, full name, gender, images.
        The information that we request will be retained by us and used as
        described in this privacy policy. The app does use third-party services
        that may collect information used to identify you.
      </p>

       {/* Child Sexual Abuse and Exploitation Section */}
       <p style={{ fontSize: 8 }}> Prevention of Child Sexual Abuse and Exploitation (CSAE) </p>
      <p style={{ fontSize: 6 }}>
        At collectedAF, we are committed to ensuring the safety and well-being
        of children and preventing any misuse of our platform for purposes of
        child sexual abuse and exploitation (CSAE). We strictly prohibit the use
        of our Service to create, share, or distribute content that involves or
        promotes CSAE.
      </p>
      <p style={{ fontSize: 6 }}>
        We actively monitor and enforce policies to detect and prevent such
        behavior. This includes the use of moderation tools, reporting
        mechanisms, and, where necessary, collaboration with law enforcement and
        organizations specializing in the prevention of CSAE.
      </p>
      <p style={{ fontSize: 6 }}>
        If you encounter any content or activity that appears to involve CSAE,
        please report it immediately by contacting us at{' '}
        <a href="mailto:support@collectedAF.com">support@collectedAF.com</a>.
        Your report will be treated with the utmost urgency and confidentiality.
      </p>
      <p style={{ fontSize: 6 }}>
        We reserve the right to take immediate action against accounts or
        individuals found to be engaging in such activities, including but not
        limited to reporting to appropriate authorities and terminating access to
        our Service.
      </p>

      <p style={{ fontSize: 6 }}>
        Link to the privacy policy of third-party service providers used by the
        app:
      </p>
      <a href={`https://policies.google.com/privacy`}>
        <p style={{ fontSize: 8, marginTop: 10, marginBottom: 10 }}>
          Google Play Services
        </p>
      </a>
      <a href={`https://firebase.google.com/policies/analytics`}>
        <p style={{ fontSize: 8, marginTop: 10, marginBottom: 10 }}>
          Google Analytics for Firebase
        </p>
      </a>
      <a href={`https://firebase.google.com/support/privacy/`}>
        <p style={{ fontSize: 8, marginTop: 10, marginBottom: 10 }}>
          Google Crashlytics
        </p>
      </a>
      <a href={`https://www.facebook.com/about/privacy/update/printable`}>
        <p style={{ fontSize: 8, marginTop: 10, marginBottom: 10 }}>Meta</p>
      </a>
      <a href={`https://expo.dev/privacy`}>
        <p style={{ fontSize: 8, marginTop: 10, marginBottom: 10 }}>Expo</p>
      </a>

      <p style={{ fontSize: 8 }}> Log Data </p>
      <p style={{ fontSize: 6 }}>
        We want to inform you that whenever you use our Service, in a case of an
        error in the app we collect data and information (through third-party
        products) on your phone called Log Data. This Log Data may include
        information such as your device Internet Protocol (“IP”) address, device
        name, operating system version, the configuration of the app when
        utilizing our Service, the time and date of your use of the Service, and
        other statistics.
      </p>

      <p style={{ fontSize: 8 }}> Cookies </p>
      <p style={{ fontSize: 6 }}>
        Cookies are files with a small amount of data that are commonly used as
        anonymous unique identifiers. These are sent to your browser from the
        websites that you visit and are stored on your device's internal memory.
        This Service does not use these “cookies” explicitly. However, the app
        may use third-party code and libraries that use “cookies” to collect
        information and improve their services. You have the option to either
        accept or refuse these cookies and know when a cookie is being sent to
        your device. If you choose to refuse our cookies, you may not be able to
        use some portions of this Service.
      </p>

      <p style={{ fontSize: 8 }}> Service Providers </p>
      <p style={{ fontSize: 6 }}>
        We may employ third-party companies and individuals due to the following
        reasons:
      </p>
      <ul style={{ fontSize: 6 }}>
        <li>To facilitate our Service;</li>
        <li>To provide the Service on our behalf;</li>
        <li>To perform Service-related services; or</li>
        <li>To assist us in analyzing how our Service is used.</li>
      </ul>
      <p style={{ fontSize: 6 }}>
        We want to inform users of this Service that these third parties have
        access to their Personal Information. The reason is to perform the tasks
        assigned to them on our behalf. However, they are obligated not to
        disclose or use the information for any other purpose.
      </p>

      <p style={{ fontSize: 8 }}> Security </p>
      <p style={{ fontSize: 6 }}>
        We value your trust in providing us your Personal Information, thus we
        are striving to use commercially acceptable means of protecting it. But
        remember that no method of transmission over the internet, or method of
        electronic storage is 100% secure and reliable, and we cannot guarantee
        its absolute security.
      </p>

      <p style={{ fontSize: 8 }}> Links to Other Sites </p>
      <p style={{ fontSize: 6 }}>
        This Service may contain links to other sites. If you click on a
        third-party link, you will be directed to that site. Note that these
        external sites are not operated by us. Therefore, we strongly advise you
        to review the Privacy Policy of these websites. We have no control over
        and assume no responsibility for the content, privacy policies, or
        practices of any third-party sites or services.
      </p>

      <p style={{ fontSize: 8 }}> SMS Messaging & 2FA Verification </p>
<p style={{ fontSize: 6 }}>
  collectedAF uses SMS-based messaging for account security and identity verification purposes only. 
  This includes two-factor authentication (2FA) to confirm user logins and safeguard user accounts.
</p>
<p style={{ fontSize: 6 }}>
  By signing up for our Service and providing your phone number, you consent to receiving one-time 
  passcodes via SMS for secure login. These messages are automated, contain no promotional content, 
  and are essential to the function of the app.
</p>
<p style={{ fontSize: 6 }}>
  Users can manage their phone number and opt-in status through account settings. For more information, 
  contact us at <a href="mailto:support@collectedAF.com">support@collectedAF.com</a>.
</p>


      <p style={{ fontSize: 8 }}> Children's Privacy </p>
      <p style={{ fontSize: 6 }}>
        These Services do not address anyone under the age of 13. We do not
        knowingly collect personally identifiable information from children
        under 13 years of age. In the case we discover that a child under 13 has
        provided us with personal information, we immediately delete this from
        our servers. If you are a parent or guardian and you are aware that your
        child has provided us with personal information, please contact us so
        that we will be able to do the necessary actions.
      </p>

      <p style={{ fontSize: 8 }}> Changes to This Privacy Policy </p>
      <p style={{ fontSize: 6 }}>
        We may update our Privacy Policy from time to time. Thus, you are
        advised to review this page periodically for any changes. We will notify
        you of any changes by posting the new Privacy Policy on this page. This
        policy is effective as of 2022-04-05.
      </p>

      <p style={{ fontSize: 8 }}> Contact Us </p>
      <p style={{ fontSize: 6 }}>
        If you have any questions or suggestions about our Privacy Policy, do
        not hesitate to contact us at support@collectedAF.com.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
